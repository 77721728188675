import {defineStore} from "pinia";
import {ref} from "vue";

export const useBreadcrumbStore = defineStore(
  'breadcrumb',
  () => {
    const breadcrumbs = ref();
    const prevBreadcrumbs = ref();
    return {breadcrumbs, prevBreadcrumbs};
  },
  {
    persist: {
      storage: sessionStorage,
    }
  },
);
