<template>
  <CHeader class="mb-4 header" position="sticky">
    <CContainer fluid>
      <AppBreadcrumb/>
      <CButton @click="exit">Выйти</CButton>
    </CContainer>
  </CHeader>
</template>

<script>
import AppBreadcrumb from '@/components/appBreadcrumb/appBreadcrumb.vue';
import {useAuthStore} from "@/store/auth";
import {CButton, CContainer, CHeader} from "@coreui/vue";

export default {
  name: 'AppHeader',
  components: {
    CHeader,
    CContainer,
    CButton,
    AppBreadcrumb,
  },
  methods: {
    exit() {
      const authStore = useAuthStore();

      authStore.logout();
    }
  }
};
</script>

<style lang="scss" scoped src="./appHeader.scss">
</style>
