import {createRouter, createWebHistory} from 'vue-router';

import defaultLayout from '@/layouts/defaultLayout.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {title: 'C&U'},
    component: defaultLayout,
    redirect: '/companies',
    children: [
      {
        path: '/companies',
        name: 'companies',
        meta: {title: 'Управляющая компания'},
        component: () => import(/* webpackChunkName: "complex" */ '@/pages/companies/companies.vue'),
        children: [
          {
            path: ':companyId',
            name: 'company',
            meta: {title: 'Компания'},
            component: () => import(/* webpackChunkName: "complex" */ '@/pages/companies/companiesCompany/companiesCompany.vue'),
          }
        ]
      },
      {
        path: '/complex/',
        name: 'complex',
        meta: {title: 'Жилые комплексы'},
        component: () => import(/* webpackChunkName: "complex" */ '@/pages/complexes/complexes.vue'),
        children: [
          {
            path: 'houses/:complexId/',
            name: 'complex-houses',
            meta: {title: 'Дома'},
            props: {id: null},
            component: () => import(/* webpackChunkName: "category-detail" */ '@/pages/complexes/complexesHouses/complexesHouses.vue'),
            children: [
              {
                path: 'enterways/:houseId/',
                name: 'complex-entryways',
                meta: {title: 'Подъезды'},
                props: {id: null},
                component: () => import(/* webpackChunkName: "category-detail" */ '@/pages/complexes/complexesEntryways/complexesEntryways.vue'),
                children: [
                  {
                    path: 'floors/:entrywayId/',
                    name: 'complex-floors',
                    meta: {title: 'Этажи'},
                    props: {id: null},
                    component: () => import(/* webpackChunkName: "category-detail" */ '@/pages/complexes/complexesFloors/complexesFloors.vue'),
                    children: [
                      {
                        path: 'premises/:floorId/',
                        name: 'complex-premises',
                        meta: {title: 'Помещения'},
                        props: {id: null},
                        component: () => import(/* webpackChunkName: "category-detail" */ '@/pages/complexes/complexesPremises/complexesPremises.vue'),
                      }
                    ]
                  }
                ]
              },
            ]
          }
        ]
      },
      {
        path: '/requests/',
        name: 'requests',
        meta: {title: 'Заявки'},
        component: () => import(/* webpackChunkName: "complex" */ '@/pages/requests/requests.vue'),
        children: [
          {
            path: ':placeId',
            name: 'problems',
            meta: {title: 'Проблемы'},
            component: () => import(/* webpackChunkName: "complex" */ '@/pages/requests/requestsProblem/requestsProblem.vue'),
          }
        ]
      },
      {
        path: '/personalAccount/',
        name: 'personalAccount',
        meta: {title: 'Лицевые счета'},
        component: () => import(/* webpackChunkName: "complex" */ '@/pages/personalAccount/personalAccount.vue'),
        children: [
          {
            path: ':accountId',
            name: 'personalAccountInfo',
            meta: {title: 'Информация о лицевом счете'},
            component: () => import(/* webpackChunkName: "complex" */ '@/pages/personalAccount/personalAccountInfo/personalAccountInfo.vue'),
          }
        ]
      },
      {
        path: '/counters/',
        name: 'counters',
        meta: {title: 'Счетчики'},
        component: () => import(/* webpackChunkName: "complex" */ '@/pages/counters/counters.vue'),
        children: [
          {
            path: ':counterId',
            name: 'readings',
            meta: {title: 'Показания'},
            component: () => import(/* webpackChunkName: "complex" */ '@/pages/counters/countersReadings/countersReadings.vue'),
          }
        ]
      },
      {
        path: '/documents',
        name: 'documents',
        meta: {title: 'Документы'},
        component: () => import(/* webpackChunkName: "complex" */ '@/pages/documents/documents.vue'),
      },
      {
        path: '/presets',
        name: 'constructor',
        meta: {title: 'Конструктор уведомлений'},
        component: () => import(/* webpackChunkName: "complex" */ '@/pages/constructor/constructor.vue'),
      },
      {
        path: '/faq/',
        name: 'faqGroups',
        meta: {title: 'Группы вопросов'},
        component: () => import(/* webpackChunkName: "complex" */ '@/pages/faq/faqGroups/faqGroups.vue'),
        children: [
          {
            path: ':groupId',
            name: 'faqQuestions',
            meta: {title: 'Вопросы группы'},
            component: () => import(/* webpackChunkName: "complex" */ '@/pages/faq/faqQuestions/faqQuestions.vue'),
          },
        ]
      },
      {
        path: '/gallery',
        name: 'gallery',
        meta: {title: 'Галерея изоброжений Новости'},
        component: () => import(/* webpackChunkName: "complex" */ '@/pages/gallery/gallery.vue'),
      },
      {
        path: '/logs',
        name: 'log',
        meta: {title: 'Логи импорта 1С'},
        component: () => import(/* webpackChunkName: "complex" */ '@/pages/logs/logs.vue'),
      },
    ]
  },
  {
    path: '/auth',
    name: 'auth',
    meta: {title: 'Вход'},
    component: () => import(/* webpackChunkName: "complex" */ '@/pages/auth/auth.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return {top: 0};
  },
});

export default router;
