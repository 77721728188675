import axios from 'axios';
import {useAuthStore} from "@/store/auth";

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Access-Control-Allow-Origin': '*',
  }
});

axiosInstance.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response.status === 401) {
    useAuthStore().refresh();
  }

  return Promise.reject(error);
});

export default axiosInstance;
