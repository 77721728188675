import { useToast } from 'vue-toastification';
import BaseToast from "@/components/baseToast/baseToast.vue";

export const useNotification = () => {
  const toast = useToast();

  const showError = (message) => {
    toast.error({
      component: BaseToast,
      props: {
        content: message ?? 'Что-то пошло не так',
        color: 'primary'
      },
    });
  };

  return { showError };
};
