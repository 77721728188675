<template>
  <router-view />
  <ModalsContainer/>
</template>

<script setup>
import { ModalsContainer } from "vue-final-modal";
import {useAuthStore} from "@/store/auth";
import router from "@/router";

const authStore = useAuthStore();

if(!authStore.isAuth) {
  router.push('/auth');
}

window.addEventListener('beforeunload', () => {
  if (authStore.isRemember === false) {
    localStorage.clear();
  }
});
</script>

<style lang="scss">
@import 'styles/style';
</style>
