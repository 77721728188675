import {createApp} from 'vue';
import {createPinia} from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import Toast from 'vue-toastification';
import "vue-toastification/dist/index.css";
import CoreuiVue from '@coreui/vue';
import CIcon from '@coreui/icons-vue';
import {iconsSet as icons} from '@/assets/icons';
import router from './router';
import {createVfm} from "vue-final-modal";
import 'vue-final-modal/style.css';

import App from './app.vue';
import {ReCapchaOptions} from "@/service/reCapchaSettings";
import {ToastSettings} from "@/service/toastSettings";
import {VueReCaptcha} from "vue-recaptcha-v3";

const app = createApp(App);
const pinia = createPinia();

const vfm = createVfm();

pinia.use(piniaPluginPersistedstate);

app.use(pinia);
app.use(router);
app.use(vfm);
app.use(CoreuiVue);
app.use(VueReCaptcha, ReCapchaOptions);
app.provide('icons', icons);
app.component('CIcon', CIcon);
app.use(Toast, ToastSettings);

app.config.warnHandler = () => {
  return 0;
};

app.mount('#app');
