export const ToastSettings = {
  transition: 'Vue-Toastification__bounce',
  maxToasts: 5,
  position: 'top-right',
  timeout: 3000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  hideProgressBar: true,
  closeButton: false,
  icon: false,
  rtl: false
};
