import { defineStore } from "pinia";
import { useLocalStorage } from '@vueuse/core';
import {ref} from "vue";
import axiosInstance from '@/api/axiosInstance';
import router from "@/router";
import {useNotification} from "../../../composables/useNotification";

export const useAuthStore = defineStore('auth', () => {
  const isAuth = useLocalStorage('isAuth', ref(false));
  const isRemember = useLocalStorage('isRemember', ref(true));
  const accessToken = useLocalStorage('accessToken', ref(''));
  const refreshToken = useLocalStorage('refreshToken', ref(''));
  const isLoading = ref(false);

  const { showError } = useNotification();

  const logout = () => {
    localStorage.clear();
    window.location.reload();
  };

  const signIn = async (email, password, recaptcha) => {
    try {
      isLoading.value = true;

      const res = await axiosInstance.post('admin/web/login', {
        email,
        password,
        recaptcha
      },{
        headers: {
          "Content-Type": "application/json"
        }
      });

      if (res.status === 200) {
        const { data } = res.data;
        accessToken.value = `${data.tokenType} ${data.accessToken}`;
        refreshToken.value = data.refreshToken;

        if (isRemember) {
          isAuth.value = true;
        }

        await router.push('/');
      }
    } catch (e) {
      if (e.toString().match(/(\d+)/)[0] === '422') {
        showError('Неверные данные!');
      } else if (e.toString().match(/(\d+)/)[0] === '403') {
        showError('Время для отправки следующей попытки ещё не наступило!');
      } else if (e.toString().match(/(\d+)/)[0] === '400') {
        showError('Сервер не отвечает!');
      }
      console.error(e);
    } finally {
      isLoading.value = false;
    }
  };

  const refresh = async () => {
    try {
      isLoading.value = true;

      const res = await axiosInstance.post('refresh-token', {
        'refresh_token': refreshToken.value
      });

      if (res.status === 200) {
        accessToken.value = `${res.data.data.tokenType} ${res.data.data.accessToken}`;
        refreshToken.value = res.data.data.refreshToken;

        if (isRemember) {
          isAuth.value = true;
        }

        await router.push('/');
      }
    } catch (e) {
      console.error(e);
    } finally {
      isLoading.value = false;
    }
  };

  return {
    isAuth,
    isLoading,
    accessToken,
    isRemember,
    signIn,
    refresh,
    logout
  };
});
