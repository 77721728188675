<template>
  <div>
    <AppSidebar />
    <div class="wrapper d-flex flex-column min-vh-100 bg-white">
      <AppHeader />
      <div  class="body flex-grow-1 px-3">
        <CContainer fluid>
          <router-view />
        </CContainer>
      </div>
    </div>
  </div>
</template>
<script>
import { CContainer } from '@coreui/vue';
import AppHeader from '@/components/appHeader/appHeader.vue';
import AppSidebar from '@/components/appSideBar/appSideBar.vue';

export default {
  name: 'DefaultLayout',
  components: {
    AppHeader,
    AppSidebar,
    CContainer,
  },
};
</script>
